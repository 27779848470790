/* eslint jsx-a11y/html-has-lang: 0 */
import React, { useState, useRef, useEffect } from "react"
import Slider from "react-slick"
import styled from "styled-components"
import { Helmet } from "react-helmet"
import { MEDIA_MIN_MEDIUM } from "../../../constants"

const Container = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: ${(p) => p.theme.colors.white};
  z-index: 10000;
  min-height: 100vh;
  transition: opacity 0.2s;
  opacity: ${(p) => (p.openZoom ? "1" : "0")};
  z-index: ${(p) => (p.openZoom ? "10000" : "-1")};
  display: flex;
  align-items: center;
`

const CloseButton = styled.div`
  position: absolute;
  top: 2rem;
  right: 3.5rem;
  font-size: 3rem;
  cursor: pointer;
  z-index: 1000;
`

const ZoomSlider = styled(Slider)`
  width: 100%;
  margin: 0 auto;
  touch-action: auto;

  img {
    max-height: 100%;
    max-width: 100%;
  }

  .slick-arrow {
    z-index: 1000;
    font-size: 4rem;
    color: ${(p) => p.theme.colors.black};
    top: 50vh;
  }

  .slick-prev {
    left: -3rem;

    ${MEDIA_MIN_MEDIUM} {
      left: 4rem;
    }
  }

  .slick-next {
    right: -3rem;

    ${MEDIA_MIN_MEDIUM} {
      right: 4rem;
    }
  }

  .slick-dots {
    position: absolute;
    bottom: -6rem;

    li {
      button {
        &:before {
          color: ${(p) => p.theme.colors.primary};
          font-size: 0.8rem;
        }
      }

      &.slick-active {
        button {
          &:before {
            color: ${(p) => p.theme.colors.primary};
          }
        }
      }
    }
  }
`

const ImageWrapper = styled.div`
  margin: 0 auto;

  ${MEDIA_MIN_MEDIUM} {
    overflow-y: scroll;
    height: 100vh;
  }
`

const ImageNumber = styled.div`
  position: absolute;
  top: 3rem;
  left: 3.5rem;
  z-index: 1000;
`

const ImageZoom = ({
  openZoom,
  setOpenZoom,
  slideContent,
  imageIndex,
  name,
  ...other
}) => {
  const zoomSlider = useRef(null)
  const [currentSlide, setCurrentSlide] = useState(imageIndex)

  const SlickButtonFix = ({
    currentSlide,
    slideCount,
    children,
    next,
    previous,
    ...props
  }) => <span {...props}>{children}</span>

  const NextArrow = (
    <SlickButtonFix>
      <i className="fal fa-chevron-right"></i>
    </SlickButtonFix>
  )
  const PrevArrow = (
    <SlickButtonFix>
      <i className="fal fa-chevron-left"></i>
    </SlickButtonFix>
  )

  useEffect(() => {
    zoomSlider.current.slickGoTo(imageIndex, true)
  }, [imageIndex])

  const mainImageSettings = {
    dots: true,
    infinite: true,
    arrows: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: React.cloneElement(NextArrow, {
      next: "true"
    }),
    prevArrow: React.cloneElement(PrevArrow, {
      previous: "true"
    }),
    initialSlide: imageIndex,
    afterChange: (indexOfCurrentSlide) => {
      setCurrentSlide(indexOfCurrentSlide)
    }
  }

  return (
    <Container openZoom={openZoom} {...other}>
      {openZoom && (
        <Helmet>
          <html className="disableScroll" />
          <body className="disableScroll" />
        </Helmet>
      )}
      <ImageNumber>
        {currentSlide + 1} / {slideContent.length}
      </ImageNumber>
      <CloseButton
        onClick={() => {
          setOpenZoom(false)
        }}
      >
        <i className="fal fa-times"></i>
      </CloseButton>
      <ZoomSlider {...mainImageSettings} ref={zoomSlider}>
        {slideContent.map((content, index) => (
          <ImageWrapper key={index}>{content}</ImageWrapper>
        ))}
      </ZoomSlider>
    </Container>
  )
}

export default ImageZoom
