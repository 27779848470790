import React, { useState, useContext, useEffect } from "react"
import styled from "styled-components"
import Helmet from "react-helmet"
import { graphql } from "gatsby"
import { useTranslation } from "react-i18next"
import loadable from "@loadable/component"
import queryString from "query-string"
import Dinero from "dinero.js"
import { BrinkContext } from "../components/context/BrinkContext"
import {
  containerMaxWidth,
  MEDIA_MIN_MEDIUM,
  MEDIA_MIN_X_LARGE
} from "../constants"
import Layout from "../components/Layout"
import ImageGallery from "../components/product/page/ImageGallery"
import Usp from "../components/widgets/Usp"
import Price from "../components/product/Price"
import Actions from "../components/product/page/actions/Actions"
import Description from "../components/product/page/Description"
import * as events from "../components/context/utils/events"
import WidgetLoader from "../components/widgets/WidgetLoader"
import FakeOfferCountdown from "../components/product/page/FakeOfferCountdown"
import { getVariantStock } from "../helpers/stock"

const ProductSlider = loadable(() =>
  import("../components/widgets/ProductSlider")
)

const Tabs = loadable(() => import("../components/product/page/tabs/Tabs"))

const Container = styled.div`
  padding: 2rem 0;

  ${MEDIA_MIN_MEDIUM} {
    padding: 6rem 0;
  }
`

const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: ${containerMaxWidth};
  margin: 0 auto 2rem;
  padding: 3rem 2rem;

  ${MEDIA_MIN_MEDIUM} {
    flex-wrap: nowrap;
  }

  ${MEDIA_MIN_X_LARGE} {
    flex-wrap: nowrap;
    padding: 4rem 0;
  }
`

const Information = styled.div`
  width: 100%;
  position: relative;
  padding: 3rem 0;

  ${MEDIA_MIN_MEDIUM} {
    width: 50%;
    padding: 0 0 0 3rem;
  }

  ${MEDIA_MIN_X_LARGE} {
    padding: 0 0 0 5rem;
  }
`

const Name = styled.h1`
  font-size: 1.5rem;
  line-height: 2rem;
  margin: 0 0 1rem;
  padding-right: 9rem;
  text-align: left;
  font-weight: 700;
  text-transform: uppercase;

  ${MEDIA_MIN_MEDIUM} {
    margin-bottom: 1.5rem;
    padding-right: 12rem;
    line-height: 3.6rem;
  }
`

const FormattedPrice = styled(Price)`
  margin-top: 3rem;
  margin-bottom: 2.4rem;

  ${MEDIA_MIN_MEDIUM} {
    margin-bottom: 3rem;
  }

  span {
    font-size: 1.8rem;
  }
`

const MatchingProducts = styled(ProductSlider)`
  padding: 2rem 2.5rem;

  h2 {
    text-align: center;
  }

  ${MEDIA_MIN_MEDIUM} {
    padding: 2rem 0;
  }
`

const RelatedProducts = styled(ProductSlider)`
  padding: 2rem 2.5rem;

  h2 {
    text-align: center;
  }

  ${MEDIA_MIN_MEDIUM} {
    padding: 2rem 0;
  }
`

const Announcement = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: -1rem auto 2rem;
  padding: 1.2rem 2rem 1.2rem 6rem;
  background: ${(p) => p.theme.colors.third};
  color: ${(p) => p.theme.colors.fourth};
  position: relative;

  ${MEDIA_MIN_MEDIUM} {
    padding: 2rem 3rem;
  }

  i {
    position: absolute;
    font-size: 2.5rem;
    left: 2rem;
    top: 1.2rem;

    ${MEDIA_MIN_MEDIUM} {
      position: relative;
      left: auto;
      top: auto;
      margin: 0.2rem 1rem 0 0;
    }
  }
`

const AnnouncementText = styled.span`
  text-align: left;
  flex: 1;

  ${MEDIA_MIN_MEDIUM} {
    text-align: center;
    flex: unset;
  }
`

const toDinero = (amount, currencyUnit) => {
  return new Dinero({ amount: amount, currency: currencyUnit })
}

const ProductPage = ({
  data: { sanityProduct, brinkcommerce },
  pageContext,
  location
}) => {
  const { variant, discountCode } = queryString.parse(location.search)
  const [sanityProductVariant, setSanityProductVariant] = useState()
  const [variantInfo, setVariantInfo] = useState([])
  const [stocks, setStocks] = useState({})
  const { t } = useTranslation("translations")
  const {
    languageCode,
    currentStore,
    getStocks,
    setListName,
    listName,
    setDiscountCode,
    addDiscount,
    cart
  } = useContext(BrinkContext)
  const { currencyUnit } = currentStore
  const { clickProduct } = location.state || {}
  const { prevPath } = location.state || {}

  const {
    variants,
    displayName,
    matchingProducts,
    relatedProducts,
    usps,
    comingSoon,
    widgets,
    productDescription,
    category,
    badge,
    sizeGuide,
    title,
    useProductImages,
    siblings,
    mainImage: productMainImage,
    images: productImages
  } = sanityProduct

  const {
    mainImage: variantMainImage,
    images: variantImages,
    strength,
    shortDescription,
    customerAttribute_SKU,
    customerAttribute_productFeed_mpn,
    _id
  } = sanityProductVariant || variants[0]

  const { announcement, badge: categoryBadge } = category
  const brinkVariants = brinkcommerce.getProduct.relatedVariants
  const brinkVariant = brinkVariants.filter((v) => v.id === _id)[0]
  const price = brinkVariant?.price.find(
    (p) => p.currencyUnit === currencyUnit
  ).amount
  const mainImage = useProductImages ? productMainImage : variantMainImage
  const images = useProductImages ? productImages : variantImages

  const useMountEffect = (fun) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(fun, [])
  }

  useMountEffect(() => {
    getStocks(
      sanityProduct.variants
        ?.map((v) => v._id)
        .concat(siblings?.map((s) => s.variants.map((v) => v._id)))
    ).then((stocks = []) => {
      setStocks(
        stocks.reduce((acc, stock) => {
          acc[stock.productId] = stock
          return acc
        }, {})
      )
    })
  })

  useEffect(() => {
    const applyCode = async (discountCode) => {
      await addDiscount({ code: discountCode })
    }

    if (discountCode) {
      applyCode(discountCode)
      setDiscountCode(discountCode)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [discountCode])

  useEffect(() => {
    if (!variants.length) return null
    setSanityProductVariant(variants.find((bv) => bv.slug.current === variant))
  }, [variant, variants])

  useMountEffect(() => {
    if (!brinkVariants.length) return null
    getStocks(brinkVariants.map((brinkVariant) => brinkVariant.id)).then(
      (stocks = []) => {
        setStocks(
          stocks.reduce((acc, stock) => {
            acc[stock.productId] = stock
            return acc
          }, {})
        )
      }
    )
  }, [brinkVariants])

  useEffect(() => {
    const variantInfo = variants?.map((v) => {
      const stock = stocks[v._id] || {}
      const cartQuantity = (
        cart.cartItems.find((cartItem) => {
          return cartItem.id === v._id && stock.queryDateTime < cartItem.updated
        }) || { quantity: 0 }
      ).quantity
      const currentStock = Math.max((stock.stock || 0) - cartQuantity, 0)

      return {
        id: v._id,
        name: displayName[languageCode] || displayName.en,
        stock: stock.stock,
        outOfStock: Number.isInteger(stock.stock) && currentStock === 0,
        currentStock: currentStock,
        comingSoon: v.comingSoon,
        slug: `${sanityProduct.slug.current}/?variant=${v.slug.current}`,
        size: v.size,
        color: v.color,
        title: v.title
      }
    })
    setVariantInfo(variantInfo)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variants, stocks, cart.cartItems])

  useEffect(() => {
    if (clickProduct && clickProduct.listName) {
      events.clickProduct({
        product: sanityProduct,
        listName: clickProduct.listName,
        positionInList: clickProduct.positionInList
      })
      setListName(clickProduct.listName)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clickProduct, setListName])

  useEffect(() => {
    if (!brinkVariant) return null
    events.viewProduct({
      sanityProductVariant,
      sanityProduct,
      languageCode,
      prices: brinkVariant.price,
      currentStore,
      listName: listName || sanityProduct.title
    })
  }, [
    languageCode,
    sanityProductVariant,
    sanityProduct,
    brinkVariant,
    currentStore,
    title,
    listName
  ])

  if (!variants.length || !brinkVariant || !price) return null

  const currentVariant =
    variants.length === 1 ? variants[0] : sanityProductVariant

  const allSizesOutOfStock = variants.every(
    (variant) => getVariantStock(variant, stocks, cart) === 0
  )

  return (
    <Layout
      meta={{
        title: displayName[languageCode] || displayName.en,
        description: shortDescription[languageCode] || shortDescription.en
      }}
      invertedHeader
      pageContext={pageContext}
      slug={`product/${sanityProduct.slug.current}`}
    >
      <Helmet>
        <script type="application/ld+json">
          {`
        {
          "@context": "https://schema.org/",
          "@type": "Product",
          "name": "${displayName[languageCode] || displayName.en} ${strength}",
          "image": [
            "${mainImage.asset.url}"
          ],
          "description": "${
            shortDescription[languageCode] || shortDescription.en
          }",
          "sku": "${customerAttribute_SKU}",
          "mpn": "${customerAttribute_productFeed_mpn}",
          "brand": {
            "@type": "Brand",
            "name": "Rondure"
          },
          "offers": {
            "@type": "Offer",
            "url": "${location.href}",
            "priceCurrency": "${currencyUnit}",
            "price": "${toDinero(price, currencyUnit).toFormat("0,0")}",
            "availability": "https://schema.org/InStock"
          }
        }
      `}
        </script>
      </Helmet>
      <Container>
        <Grid>
          <ImageGallery
            name={displayName.en}
            mainImage={mainImage}
            images={images}
            currentVariant={currentVariant}
            variants={variantInfo}
            languageCode={languageCode}
            badge={badge?.en && badge}
            categoryBadge={categoryBadge?.en && categoryBadge}
            allSizesOutOfStock={allSizesOutOfStock}
          />
          <Information>
            <Name>{displayName[languageCode] || displayName.en}</Name>
            <FormattedPrice
              price={brinkVariant.price}
              allDiscount={
                brinkVariant.discount && brinkVariant.discount.length > 0
                  ? brinkVariant.discount
                  : null
              }
            />
            <Actions
              currentVariant={
                variants.length === 1 ? variants[0] : sanityProductVariant
              }
              variants={variantInfo}
              siblings={siblings}
              comingSoon={comingSoon}
              currentProduct={sanityProduct}
              path={prevPath}
              sizeGuide={sizeGuide}
              allSizesOutOfStock={allSizesOutOfStock}
            />
            {announcement && announcement.enabled && announcement.text.en && (
              <Announcement>
                <i className="fal fa-bells"></i>
                <AnnouncementText>
                  {announcement.text[languageCode] || announcement.text.en}
                </AnnouncementText>
                {announcement.enableCountdown && <FakeOfferCountdown />}
              </Announcement>
            )}
            {usps &&
              usps.map((usp) => (
                <Usp key={`${usp.id}-primary`} data={usp} isOnProductPage />
              ))}
            <Description
              languageCode={languageCode}
              productDescription={productDescription}
              category={category.description}
            />
            <Tabs product={sanityProduct} />
          </Information>
        </Grid>
        <WidgetLoader widgets={widgets} />
        {matchingProducts && matchingProducts.length > 0 && (
          <MatchingProducts
            products={matchingProducts}
            title={t("Matching products")}
            columns="4"
          />
        )}
        {relatedProducts && relatedProducts.length > 0 && (
          <RelatedProducts
            products={relatedProducts}
            title={t("Related products")}
            columns="4"
          />
        )}
        <Grid></Grid>
      </Container>
    </Layout>
  )
}

export default ProductPage

export const query = graphql`
  query ($sanityProductId: String!, $brinkProductId: ID!) {
    sanityProduct(_id: { eq: $sanityProductId }) {
      ...Product
    }
    brinkcommerce {
      getProduct(id: $brinkProductId) {
        relatedVariants {
          id
          name
          slug
          price {
            amount
            currencyUnit
          }
          discount {
            amount
            currencyUnit
          }
        }
      }
    }
  }
`
