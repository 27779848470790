import React, { useContext } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import { MEDIA_MIN_MEDIUM } from "../../../../constants"
import { BrinkContext } from "../../../context/BrinkContext"
import ColorSwatch from "../../../ui/ColorSwatch"

const Container = styled.div`
  margin: 0 0 2.4rem;
  flex-wrap: wrap;
  padding-bottom: 2rem;
  border-bottom: 0.1rem solid ${(p) => p.theme.colors.lightBorder};

  ${MEDIA_MIN_MEDIUM} {
    margin: 0 0 2.5rem;
  }
`

const Label = styled.span`
  width: 100%;
  text-transform: uppercase;
  display: block;
  font-size: 1.3rem;
  padding-bottom: 0.3rem;
`

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

const ColorLink = styled(Link)`
  margin: 1rem 1.5rem 0.5rem 0;
  padding-bottom: 0.5rem;
  border-bottom: 0.2rem solid
    ${(p) => (p.active ? p.theme.colors.black : "transparent")};
  cursor: ${(p) => (p.active ? "initial" : "pointer")};
  pointer-events: ${(p) => (p.active ? "none" : "all")};
  transition: all 0.2s;
`

const ColorButton = styled(ColorSwatch)`
  color: ${(p) => p.theme.colors.black};
  cursor: ${(p) => (p.active ? "initial" : "pointer")};
  pointer-events: ${(p) => (p.active ? "none" : "all")};
  transition: all 0.2s;
  opacity: ${(p) => (p.outOfStock ? "0.6" : "1")};
`

const onClick = (variant) => {
  typeof window.gtag !== "undefined" &&
    window.gtag("event", "select_content", {
      content_type: "product",
      items: [
        {
          id: variant.id,
          name: variant.name,
          brand: "Rondure"
        }
      ]
    })
}

const ColorSelector = ({ siblings, currentProduct, variants }) => {
  const { t } = useTranslation("translations")
  const { languageCode } = useContext(BrinkContext)

  if (!siblings) return false

  const products = [...new Set([currentProduct, ...siblings])]
    .sort((a, b) => a.slug.current.localeCompare(b.slug.current))
    .map((p) => ({ id: p.id ? p.id : p._id, ...p }))

  return (
    <Container>
      <Label>
        {t("Color")}:{" "}
        {variants[0]?.color.displayName[languageCode] ||
          variants[0]?.color.displayName.en}
      </Label>
      <Buttons>
        {products.map((product) => {
          if (!product.active) return false

          return (
            <ColorLink
              onClick={() => onClick(product)}
              to={`/product/${product.slug.current}/`}
              key={product.id}
              state={{ disableScrollUpdate: true }}
              active={
                currentProduct && product._id === currentProduct._id
                  ? "active"
                  : undefined
              }
            >
              <ColorButton color={product.variants[0]?.color.title} />
            </ColorLink>
          )
        })}
      </Buttons>
    </Container>
  )
}

export default ColorSelector
