import React from "react"
import styled from "styled-components"
import { MEDIA_MIN_MEDIUM } from "../../../constants"

const Container = styled.div`
  padding: 0.7rem 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.1rem;
  font-weight: 400;
  margin-top: 0.75rem;
  background: ${(p) =>
    p.outOfStock
      ? p.theme.colors.disabledBackground
      : p.backgroundColor || p.theme.colors.white};
  color: ${(p) =>
    p.outOfStock
      ? p.theme.colors.disabledTextColor
      : p.textColor || p.theme.colors.secondary};
  text-transform: uppercase;
  text-align: center;

  text-shadow: ${(p) =>
    p.outOfStock ? "0px 0px 2px rgba(255, 255, 255, 0.9)" : "none"};

  ${MEDIA_MIN_MEDIUM} {
    font-size: 1.4rem;
    padding: 0.7rem 1.5rem;
  }
`

const Badge = ({ outOfStock, text, backgroundColor, textColor, ...other }) => (
  <Container
    outOfStock={outOfStock}
    backgroundColor={backgroundColor}
    textColor={textColor}
    {...other}
  >
    {text}
  </Container>
)

export default Badge
