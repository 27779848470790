import React, { useContext, useState } from "react"
import { Link } from "gatsby"
import { BrinkContext } from "../../../context/BrinkContext"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import { MEDIA_MIN_MEDIUM } from "../../../../constants"
import * as events from "../../../context/utils/events"
import SizeGuide from "./SizeGuide"

const Container = styled.div`
  margin: 0 0 1.5rem;
  flex-wrap: wrap;
  padding-bottom: 1.5rem;

  ${MEDIA_MIN_MEDIUM} {
    margin: 0;
  }
`

const LabelGrid = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 0.2rem;
  width: 100%;
`

const Label = styled.span`
  text-transform: uppercase;
  display: flex;
  align-items: center;
  font-size: 1.3rem;
  padding-bottom: 0.3rem;

  & + span {
    cursor: pointer;

    i {
      margin-left: 0.8rem;
      font-size: 1.6rem;
    }
  }
`

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 -0.5rem;
  flex-wrap: wrap;
  justify-content: flex-start;
`

const SizeButton = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  font-size: 1.3rem;
  font-weight: 700;
  border-bottom: ${(p) =>
    p.active
      ? `0.2rem solid ${p.theme.colors.black}`
      : `0.2rem solid ${p.theme.colors.lightBorder}`};
  color: ${(p) => p.theme.colors.black};
  cursor: ${(p) => (p.active ? "initial" : "pointer")};
  margin: 0.5rem 0.5rem 1rem;
  pointer-events: ${(p) => (p.active ? "none" : "all")};
  width: calc(20% - 1rem);
  padding: 1rem 0 2rem;
  transition: border-bottom 0.2s;

  ${MEDIA_MIN_MEDIUM} {
    width: 6rem;
    margin: 0.5rem 1.5rem 1rem 0;
  }

  &:hover {
    border-bottom: 0.2rem solid ${(p) => p.theme.colors.black};
  }
`

const DisabledButton = styled(SizeButton)`
  color: ${(p) => p.theme.colors.disabledTextColor} !important;
  cursor: pointer;
  position: relative;

  &:after {
    position: absolute;
    content: "";
    width: 5rem;
    height: 0.1rem;
    background: ${(p) => p.theme.colors.disabledTextColor};
    transform: rotate(45deg);
  }
`

const StockStatus = styled.div`
  color: ${(p) => p.theme.colors.seventh};
  margin-top: 0.5rem;
  display: none;
`

const SizeGuideLink = styled.div`
  font-size: 1.3rem;
  margin-left: 1rem;
  cursor: pointer;
  position: relative;
  right: 0.5rem;
  top: 0;
`

const SizeSelector = ({ currentVariant, variants, sizeGuide, path }) => {
  const { t } = useTranslation("translations")
  const { listName } = useContext(BrinkContext)
  const [openPopup, setOpenPopup] = useState(false)

  if (!variants) return false

  const currentVariantStock =
    currentVariant &&
    variants.find((v) => v.id === currentVariant._id).currentStock

  return (
    <>
      <Container>
        <LabelGrid>
          <Label>
            {t("Size")}: {currentVariant?.size?.title}
          </Label>
          {sizeGuide && (
            <SizeGuideLink onClick={() => setOpenPopup(true)}>
              <i className="fal fa-ruler-combined"></i> {t("Size guide")}
            </SizeGuideLink>
          )}
        </LabelGrid>
        <Buttons>
          {variants.map((variant, index) =>
            !variant.outOfStock ? (
              <SizeButton
                onClick={() =>
                  events.clickProduct({
                    product: variant,
                    listName: listName,
                    positionInList: index
                  })
                }
                to={`/product/${variant.slug}`}
                key={variant.id}
                active={
                  currentVariant && variant.id === currentVariant._id
                    ? "active"
                    : undefined
                }
                state={{
                  prevPath: path,
                  disableScrollUpdate: true
                }}
              >
                {variant.size.displayName.en}
              </SizeButton>
            ) : (
              <DisabledButton
                to={`/product/${variant.slug}`}
                title={t("Out of stock")}
                key={variant.id}
                active={
                  currentVariant && variant.id === currentVariant._id
                    ? "active"
                    : undefined
                }
                state={{ disableScrollUpdate: true }}
              >
                {variant.size.displayName.en}
              </DisabledButton>
            )
          )}
        </Buttons>
        {currentVariantStock > 0 && currentVariantStock < 50 && (
          <StockStatus>{t("Few left in stock")}</StockStatus>
        )}
      </Container>
      {sizeGuide && (
        <SizeGuide
          sizeGuide={sizeGuide}
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
        />
      )}
    </>
  )
}
export default SizeSelector
