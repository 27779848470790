import React, { useState, useContext } from "react"
import styled from "styled-components"
import Button from "../../../ui/Button"
import { BrinkContext } from "../../../context/BrinkContext"
import { useTranslation } from "react-i18next"
import ColorSelector from "./ColorSelector"
import SizeSelector from "./SizeSelector"

const ActionContainer = styled.div`
  display: flex;
  margin: 2rem 0 4rem;
`

const ChangeQty = styled.div`
  display: flex;
  margin-right: 1rem;
  border: 0.1rem solid ${(p) => p.theme.colors.primary};
`

const Qty = styled.div`
  display: flex;
  height: 4.8rem;
  width: 4.8rem;
  align-items: center;
  justify-content: center;
  padding-top: 0;
  transition: background 0.2s;
`

const QtyButton = styled(Button)`
  display: flex;
  width: 4.8rem;
  height: 4.8rem;
  cursor: pointer;
  padding-top: 0.1rem;
  background: transparent;
  color: ${(p) => p.theme.colors.black};

  &:hover {
    background: ${(p) => p.theme.colors.black};
    color: ${(p) => p.theme.colors.white};
  }
`
const Increase = { ...QtyButton }
const Decrease = { ...QtyButton }

const AddToCartButton = styled(Button)`
  flex: 1;
  width: 100%;
`

const DisabledButton = styled(Button)`
  background: ${(p) => p.theme.colors.disabledBackground};
  color: ${(p) => p.theme.colors.disabledTextColor};
  cursor: initial;
  text-shadow: 0 0 0.2rem rgba(255, 255, 255, 0.9);
  line-height: 1.4rem;
  width: 100%;

  &:hover {
    background: ${(p) => p.theme.colors.disabledBackground};
  }
`

const Actions = ({
  variants,
  currentProduct,
  currentVariant,
  sizeGuide,
  siblings,
  path,
  allSizesOutOfStock
}) => {
  const { addProductVariantsToCart } = useContext(BrinkContext)
  const { t } = useTranslation("translations")
  const [qty, setQty] = useState(1)

  const variantInfo = currentVariant
    ? variants.find((v) => v.id === currentVariant._id)
    : undefined

  const outOfStock = variantInfo?.outOfStock || false
  const comingSoon = variantInfo?.comingSoon || false
  const isAvailableForPurchase = currentVariant && !outOfStock && !comingSoon

  return (
    <div>
      {siblings && siblings.length > 0 && (
        <ColorSelector
          siblings={siblings}
          currentProduct={currentProduct}
          variants={variants}
        />
      )}
      {variants.length > 0 && variants[0].color && (
        <ColorSelector currentProduct={currentProduct} variants={variants} />
      )}
      {variants.length > 0 && variants[0].size && (
        <SizeSelector
          variants={variants}
          currentVariant={currentVariant}
          sizeGuide={sizeGuide}
          path={path}
        />
      )}
      <ActionContainer>
        {isAvailableForPurchase && (
          <ChangeQty>
            <Decrease
              role="button"
              aria-label="Decrease quantity"
              onClick={() => {
                if (qty !== 1) setQty(qty - 1)
              }}
            >
              <i className="fal fa-minus"></i>
            </Decrease>
            <Qty aria-live="assertive">{qty}</Qty>
            <Increase
              role="button"
              aria-label="Increase quantity"
              onClick={() => setQty(qty + 1)}
            >
              <i className="fal fa-plus"></i>
            </Increase>
          </ChangeQty>
        )}
        {allSizesOutOfStock ? (
          <DisabledButton disabled aria-label="All sizes are out of stock">
            {t("All sizes are out of stock")}
          </DisabledButton>
        ) : outOfStock ? (
          <DisabledButton disabled aria-label="Out of stock">
            {t("Out of stock")}
          </DisabledButton>
        ) : !currentVariant ? (
          <DisabledButton disabled aria-label="Please select size first">
            {t("Please select size first")}
          </DisabledButton>
        ) : comingSoon ? (
          <DisabledButton disabled aria-label="Coming soon">
            {t("Coming soon")}
          </DisabledButton>
        ) : (
          <AddToCartButton
            aria-label={t("Add to cart")}
            onClick={() => {
              addProductVariantsToCart(currentVariant._id, qty, true)
            }}
          >
            {t("Add to cart")}
          </AddToCartButton>
        )}
      </ActionContainer>
    </div>
  )
}

export default Actions
