import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import Countdown, { zeroPad } from "react-countdown"
import dayjs from "dayjs"
import styled from "styled-components"
import { MEDIA_MIN_MEDIUM } from "../../../constants"

const OfferCountdown = styled.div`
  width: 100%;
  margin-top: 0.4em;

  ${MEDIA_MIN_MEDIUM} {
    text-align: center;
    margin-top: 0.25em;
  }
`

const TimeLabel = styled.span`
  margin-right: 0.15em;
`

const TimeEl = styled.span`
  padding: 0 0.15em;
  font-weight: 700;
`

const renderer = ({ hours, minutes, seconds }) => {
  return (
    <span>
      <TimeEl>{zeroPad(hours)}h</TimeEl>
      <TimeEl>{zeroPad(minutes)}m</TimeEl>
      <TimeEl>{zeroPad(seconds)}s</TimeEl>
    </span>
  )
}

const FakeOfferCountdown = () => {
  const { t } = useTranslation("translations")
  const hourToCountDownTo = 3

  const deadline = dayjs().hour(hourToCountDownTo).minute(0).second(0).toDate()

  const nextDeadline = dayjs()
    .add(1, "days")
    .hour(hourToCountDownTo)
    .minute(0)
    .second(0)
    .toDate()

  const [currentDeadline, setCurrentDeadline] = useState(
    dayjs().isAfter(deadline) ? nextDeadline : deadline
  )

  return (
    <OfferCountdown>
      <TimeLabel>{t("Expires in")}:</TimeLabel>
      <Countdown
        renderer={renderer}
        date={currentDeadline}
        onComplete={() => setCurrentDeadline(nextDeadline)}
      />
    </OfferCountdown>
  )
}

export default FakeOfferCountdown
