import React, { useState } from "react"
import Slider from "react-slick"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import { MEDIA_MIN_MEDIUM, MEDIA_MIN_X_LARGE } from "../../../constants"
import ImageZoom from "./ImageZoom"
import Badge from "./Badge"

const Container = styled.div`
  width: 100%;
  padding: 0;
  position: relative;

  ${MEDIA_MIN_MEDIUM} {
    width: 50%;
    padding: 0 3rem 0 0;
  }

  ${MEDIA_MIN_X_LARGE} {
    padding: 0 5rem 0 0;
  }
`

const Gallery = styled(Slider)`
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  padding: 0;

  div {
    &:focus {
      outline: none;
    }
  }

  img {
    cursor: zoom-in;
  }

  .slick-list {
    transition: height 0.2s;
  }

  .slick-dots {
    padding-top: 1rem;
    position: relative;
  }

  .slick-arrow {
    width: 4rem;
    height: 4rem;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 2rem;
    display: flex !important;
    justify-content: center;
    align-items: center;
    z-index: 1;
  }

  .slick-next {
    right: -2rem;
  }

  .slick-prev {
    left: -2rem;
  }

  i {
    font-size: 2rem;
    color: ${(p) => p.theme.colors.black};
  }
`

const BadgeContainer = styled.div`
  position: absolute;
  top: 1.25rem;
  left: 2rem;
  bottom: auto;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;

  ${MEDIA_MIN_MEDIUM} {
    top: 1.25rem;
    left: 2rem;
  }
`

const ImageGallery = ({
  variants,
  currentVariant,
  mainImage,
  images,
  name,
  languageCode,
  badge,
  categoryBadge,
  allSizesOutOfStock,
  ...other
}) => {
  const { t } = useTranslation("translations")
  const slideContent = []
  const thumbContent = []

  const variantInfo = currentVariant
    ? variants.find((v) => v.id === currentVariant._id)
    : undefined
  const outOfStock = variantInfo ? variantInfo.outOfStock : false
  const comingSoon = variantInfo ? variantInfo.comingSoon : false

  slideContent.push(
    <GatsbyImage image={getImage(mainImage.asset)} alt={name} />
  )
  images.forEach((image) => {
    slideContent.push(<GatsbyImage image={getImage(image.asset)} alt={name} />)
  })

  thumbContent.push(
    <GatsbyImage image={getImage(mainImage.asset)} alt={name} />
  )
  images.forEach((image) => {
    thumbContent.push(<GatsbyImage image={getImage(image.asset)} alt={name} />)
  })

  const [openZoom, setOpenZoom] = useState(false)
  const [imageIndex, setImageIndex] = useState(0)

  const SlickButtonFix = ({
    currentSlide,
    slideCount,
    children,
    next,
    previous,
    ...props
  }) => <span {...props}>{children}</span>

  const NextArrow = (
    <SlickButtonFix>
      <i className="fal fa-chevron-right"></i>
    </SlickButtonFix>
  )
  const PrevArrow = (
    <SlickButtonFix>
      <i className="fal fa-chevron-left"></i>
    </SlickButtonFix>
  )

  const gallerySettings = {
    dots: true,
    infinite: true,
    arrows: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    nextArrow: React.cloneElement(NextArrow, {
      next: true
    }),
    prevArrow: React.cloneElement(PrevArrow, {
      previous: true
    }),
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          arrows: true,
          dots: false
        }
      }
    ]
  }

  return (
    <Container {...other}>
      <BadgeContainer>
        {allSizesOutOfStock && (
          <Badge outOfStock text={t("All sizes are out of stock")} />
        )}
        {outOfStock && <Badge outOfStock text={t("Out of stock")} />}
        {comingSoon && !outOfStock && <Badge text={t("Coming soon")} />}
        {badge && badge.en && <Badge text={badge[languageCode] || badge.en} />}
        {categoryBadge && categoryBadge.en && !outOfStock && !comingSoon && (
          <Badge text={categoryBadge[languageCode] || categoryBadge.en} />
        )}
      </BadgeContainer>

      <ImageZoom
        openZoom={openZoom}
        setOpenZoom={setOpenZoom}
        slideContent={slideContent}
        imageIndex={imageIndex}
        name={name}
      />
      <Gallery {...gallerySettings}>
        {slideContent.map((content, index) => (
          <div
            key={index}
            role="button"
            aria-label="Open zoomed image"
            tabIndex={0}
            onClick={() => {
              setOpenZoom(true)
              setImageIndex(index)
            }}
            onKeyDown={(event) => {
              if (event.keyCode !== 13) return false
              setOpenZoom(true)
              setImageIndex(index)
            }}
          >
            {content}
          </div>
        ))}
      </Gallery>
    </Container>
  )
}

export default ImageGallery
