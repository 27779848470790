import React from "react"
import styled from "styled-components"
import BlockContent from "@sanity/block-content-to-react"

const Container = styled.div`
  padding: 1rem 0;

  a {
    color: ${(p) => p.theme.colors.black};
  }

  ul {
    padding: 1rem 0;
    list-style: none;

    li {
      position: relative;
      padding: 1rem 2rem;

      &:first-letter {
        text-transform: capitalize;
      }

      &:before {
        position: absolute;
        left: 0;
        top: 1.1rem;
        font-size: 0.8rem;
        color: ${(p) => p.theme.colors.black};
        font-family: "Font Awesome 5 Pro";
        font-weight: 700;
        content: "\f111";
      }
    }
  }
`

const Description = ({ languageCode, productDescription }) => {
  const description = productDescription
    ? productDescription[languageCode]
      ? productDescription[languageCode]._rawText
      : productDescription.en._rawText
    : null

  return (
    <Container>
      {description && <BlockContent blocks={description} />}
    </Container>
  )
}

export default Description
